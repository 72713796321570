import React, {useMemo, useEffect} from "react"
import { graphql } from "gatsby"
import { mergePrismicPreviewData } from 'gatsby-source-prismic'
import SEO from "../components/seo"
// import {RichText} from 'prismic-reactjs';
import GridSlices from "../components/slices/GridSlices"
import Agenda from "../components/Agenda"


export const pageQuery = graphql`
  query QHome($lang: String!){
    prismicHome(lang: {eq: $lang}) {
      data{
        seo_title
        seo_image {
          url
        }
        seo_description {
          text
        }
        agenda_title{
          raw
        }
        agenda {
          date
          title
          link_label
          link_url {
            url
            target
          }
        }
        body {
          ... on PrismicHomeBodyCardHome {
            slice_type
            primary {
              background
              texte {
                html
                raw
              }
              image {
                url
                alt
                ...sharp
              }
              cta_contact
              cta_label
              cta_link {
                url
                target
              }
            }
          }
          
          ... on PrismicHomeBodyCardImage {
            slice_type
            primary {
              image{
                url
                alt
                ...sharp
              }
            }
          }

          ... on PrismicHomeBodyCardVide {
            slice_type
          }

          ... on PrismicHomeBodyCardTexte {
            slice_type
            primary {
              texte {
                raw
              }
            }
          }
        }
      }
    }

  }

  
`

const IS_BROWSER = typeof window !== 'undefined'

const Home = ({ path, data: staticData }) => {
  const data = useMemo(() => {
    if (!IS_BROWSER || !window.__PRISMIC_PREVIEW_DATA__) return staticData

    return mergePrismicPreviewData({
      staticData,
      previewData: window.__PRISMIC_PREVIEW_DATA__,
    })
  }, [staticData])
  // console.log(data)
  const { seo_title, seo_description, seo_image, body, agenda_title, agenda } = data.prismicHome.data
  // console.log(body[0].primary)

// console.log(body)
  useEffect(() => {
    setTimeout(() => {
      _onResize()
    }, 250)
    window.addEventListener("resize", _onResize)

    return () => window.removeEventListener("resize", _onResize)
  }, [])

  const _onResize = () => {
    const headerHeight = document.querySelector("header").offsetHeight
    const menuHeight = document.querySelector(".menu-bar").offsetHeight
    const playerHeight = window.innerHeight - (headerHeight + menuHeight + 10)
    // console.log(headerHeight, menuHeight)
    document.querySelector(".video-player").style.height = playerHeight+"px"

  }

  return (
    <div className="page-template home">
      <SEO
        pageTitle={seo_title}
        pageDescription={seo_description.text}
        pageBanner={seo_image.url}
        template="template-home"
        pathname={path}
      />
      
      {body && <GridSlices input={body} />}
      <Agenda title={agenda_title} input={agenda} />
    </div>
  )
}

export default Home
