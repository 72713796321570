import React from 'react';
import { Link } from 'gatsby'

const Cta = ({cta_label, cta_link, cta_contact, className}) => {
  // console.log(cta_link)
  const _openContact = () => PubSub.publish("CONTACT")
  const target = cta_link.target === "_blank" || cta_link.url.indexOf(".pdf") > -1
    ? "_blank"
    : "_self"
  // console.log(target)
  return (
    <div className={`btn outline ${className}`}>
      {cta_contact === true
      ? <div className="button curp" onClick={_openContact}><span>{cta_label}</span></div>
      : target === "_blank"
        ? <a href={cta_link.url} target="_blank" rel="noopener noreferrer"><span>{cta_label}</span></a>
        : <Link to={cta_link.url} ><span>{cta_label}</span></Link>
      }
    </div>
  );
};

export default Cta;