import React from 'react';
import BackgroundImage from 'gatsby-background-image'

const CardImage = ({input}) => {
  // console.log(input.primary)
  const isPortrait = input.primary.image.fluid.aspectRatio < 1
  // const imgFormat = isPortrait ? 'is-portrait' : 'is-landscape'
  const imgBackgroundSize = isPortrait ? 'contain' : 'cover'
  const imgBackgroundPosition = isPortrait ? 'left center' : 'center center'
  return (
    <div className="card card-image col-md-6 col-sm-6 col-xs-12">
      <div className="outter">
        <div className="inner">
          <div className={`card-image h100`}>
            <BackgroundImage 
              // {...input.primary.image} 
              fluid={input.primary.image.fluid}
              className="h100"
              style={{
                backgroundSize: imgBackgroundSize,
                backgroundPosition: imgBackgroundPosition
              }}
            />
        </div>
        </div>
      </div>
      
      
    </div>
  );
};

export default CardImage;