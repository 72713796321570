import React from 'react';

const CardVide = () => {
  return (
    <div className="card card-vide col-md-6 col-sm-6 col-xs-12">
      <div className="outter" >
        <div className="inner">
          
        </div>
      </div>
    </div>
  );
};

export default CardVide;