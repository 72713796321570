import React from 'react';
import Img from 'gatsby-image'

import {RichText} from 'prismic-reactjs';
import Cta from '../Cta'

const CardHome = ({input}) => {
  // console.log(input)
  const {
    background,
    cta_contact,
    cta_link,
    cta_label
  } = input.primary

  const _getBg = () => {
    return background ? background : 'transparent'
  }
  // console.log(input.primary.image)
  const imageRatio = input.primary.image.fluid.aspectRatio < .8 ? 'is-portrait' : 'is-landscape'

  return (
    <div className="card card-home col-md-6 col-sm-6 col-xs-12">
      <div className="outter" style={{
          backgroundColor: _getBg()
        }}>
        <div className="inner">
          <div className={`card-image ${imageRatio}`}>
            <Img {...input.primary.image} />
          </div>
          <div className="card-body h100">
            <div className="top">
              <div className="texte ">{RichText.render(input.primary.texte.raw)}</div>
            </div>
            <div className="bottom">
              <ul className="links x">
                <li><Cta cta_label={cta_label} cta_link={cta_link} cta_contact={cta_contact} className="blanc"/></li>
              </ul>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardHome;