import React from 'react';
import Img from 'gatsby-image'
import {RichText} from 'prismic-reactjs';
import Cta from '../Cta'
import IcoInstagram from "../../images/ico-instagram.inline.svg";
import {_getLocalizedText} from '../../core/utils'

const CardMarques = ({input}) => {
  // console.log(input)
  const {
    personnalisable,
    categorie,
    texte,
    image,
    instagram
  } = input.primary

  // const {
  //   items
  // } = input.items
  // console.log("instagram",instagram)
  const links = input.items.filter(el => el.cta_link.url != '')
  return (
    <div className="card card-marques col-md-6 col-sm-6 col-xs-12">
      <div className="outter" style={{
          backgroundColor: '#EDEDED'
        }}>
        <div className="inner">
          <div className="card-image">
            <Img {...image} />
          </div>
          <div className="card-body h100">
            <div className="top">
              
            </div>
            <div className="bottom w100" >
              <div className="texte mb1">
                <p>
                  <>
                  <span>{categorie}</span> 
                  {personnalisable === true
                  ? <span className="ttu">{_getLocalizedText("personnalisable")}</span>
                  : ''}
                  </>
                </p>
                {RichText.render(texte.raw)}
              </div>

              <div className="x xjb between-xs">
                <ul className="links x">
                  {links.map(({cta_label, cta_link}, i) => (
                    <li key={i}>
                      <Cta cta_label={cta_label} cta_link={cta_link} />
                    </li>
                  ))}
                </ul>
                {instagram && instagram.url !== "" &&
                  <a 
                  href={instagram.url} 
                  target="_blank" 
                  rel="noopener noreferrer"><IcoInstagram /></a>
                }
              </div>
              
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardMarques;