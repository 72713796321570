import React from 'react';
import CardHome from './CardHome';
import CardMarques from './CardMarques';
import CardImage from './CardImage';
import CardVide from './CardVide';
import CardTexte from './CardTexte';

const GridSlices = ({input}) => {
  // console.log(input)
  const _renderSlices = (slices) => {
    // console.log(slices)
    const _slice = slices.map((slice, i) => {
      if(slice === null)return null
      // console.log(slice.slice_type)
      switch (slice.slice_type) {
        case "card_home":
          return <CardHome key={i} input={slice} />;
        case "card_marques":
          return <CardMarques key={i} input={slice} />;
        case "card_image":
          return <CardImage key={i} input={slice} />;
        case "card_texte":
          return <CardTexte key={i} input={slice} />;
        case "card_vide":
          return <CardVide key={i} />;
        default:
          return null;
      }
    });

    return _slice;
  }

  return (
    <div className="grid-slices mb1">
      <div className="row">
        {_renderSlices(input)}
      </div>
    </div>
  );
};

export default GridSlices;