import React from 'react';
import {RichText} from 'prismic-reactjs';

const Agenda = ({title, input}) => {
  // console.log(input)
  // const {
  //   date,
  //   title,
  //   link_label,
  //   link_url
  // } = input

  return (
    <div className="agenda">
      {RichText.render(title.raw)}
      <div className="table fL strong">
        
        {input.map(({date, title, link_label, link_url}, i) => (
          <div className="agenda-item" key={i}>
            <div className="row">
              <div className="col-xs col-date">{date}</div>
              <div className="col-xs col-title">{title}</div>
              <div className="col-xs col-link text-right">
                <a href={link_url.url} target={link_url.target}>www</a>
              </div>
            </div>
          </div>
        ))}
          
      </div>
    </div>
  );
};

export default Agenda;