import React from 'react';
import {RichText} from 'prismic-reactjs';

const CardTexte = ({input}) => {
  return (
    <div className="card card-texte col-md-6 col-sm-6 col-xs-12">
      <div className="outterr" >
        <div className="innerr">
          
          <div className="card-body">
            <div className="texte strong fL">{RichText.render(input.primary.texte.raw)}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardTexte;